:root {
  --g-cipher-color-primary: #fff543;
  --g-cipher-color-black: #222222;
  --g-cipher-color-dark: #515151;
  --g-cipher-color-dark-light: #818181;
  --g-cipher-color-white: #fff;
  font-size: 16px;
  color: var(--g-cipher-color-black);

  * {
    font-size: 1rem;
  }
}

.g-cipher {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Onest', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  justify-content: center;
  line-height: 1.5;
  min-height: 100vh;
  row-gap: 1rem;

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1rem;
  }

  p {
    small {
      font-size: 0.75rem;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }

  &-header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 1rem;
    row-gap: 1rem;
    width: min(600px, calc(100vw - 2rem));

    h1 {
      margin: 0;
    }

    p {
      margin-bottom: 0;
    }

    &__logo {
      height: 2rem;
      display: flex;
      column-gap: 1rem;
      align-items: flex-end;
      text-decoration: none;

      img {
        height: 3rem;
      }

      h1 {
        line-height: 1;
        color: var(--g-cipher-color-black);
      }
    }

    &__read-more-button {
      margin-bottom: 1rem;
    }
  }

  &-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -10;
  }

  &-preloader {
    align-items: center;
    background-color: var(--g-cipher-color-white);
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: 0;
    opacity: 1;
    position: fixed;
    top: 0;
    transition: opacity 0.5s ease-out;
    width: 100vw;
    z-index: 20;

    &__title,
    &__slogan {
      opacity: 0;
      text-align: center;
      transition: opacity 2s ease-in;
    }

    &__title {
      margin-top: 0;
    }

    &--loaded {
      opacity: 0;
    }

    > * {
      max-width: 80vw;
    }

    div {
      height: 150px;
      width: 150px;
    }

    &__title--loaded,
    &__slogan--loaded {
      opacity: 1;
    }
  }

  &-language-selector {
    column-gap: 1rem;
    display: flex;
    justify-content: flex-start;
  }

  &-main {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: min(600px, calc(100vw - 2rem));

    h2 {
      margin: 0;
    }
  }

  &-data-form,
  &-decryption-complete,
  &-encryption-complete {
    background-color: var(--g-cipher-color-dark);
    border-radius: 0.5rem;
    color: var(--g-cipher-color-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    row-gap: 1rem;

    p {
      margin: 0;
    }

    &__result {
      background-color: var(--g-cipher-color-white);
      border-radius: 0.25rem;
      color: var(--g-cipher-color-black);
      cursor: copy;
      font-size: .75rem;
      padding: 1rem;
    }

    :is(input, textarea) {
      appearance: none;
      border: 2px solid var(--g-cipher-color-white);
      border-radius: 0.25rem;
      padding: 0.5rem;
      font-weight: 400;
      font-size: .9rem;

      &:focus {
        border-color: transparent;
        outline: 2px solid var(--g-cipher-color-dark-light);
      }
    }

    textarea {
      height: 5rem;
      resize: none;
    }
  }

  &-time-selector {
    background-color: var(--g-cipher-color-white);
    border-radius: 0.25rem;
    color: var(--g-cipher-color-black);
    display: flex;
    flex-direction: column;
    font-size: .75rem;
    padding: 1rem;
    row-gap: .75rem;

    h3 {
      margin: 0;
    }

    .rc-slider {
      margin: 0 7px;
      width: calc(100% - 14px);

      &-rail {
        background-color: #ccc;
      }

      &-track {
        background-color: var(--g-cipher-color-dark);
      }

      &-handle {
        background-color: var(--g-cipher-color-white);
        border: 2px solid var(--g-cipher-color-dark);
        box-shadow: none;
        opacity: 1;

        &-dragging {
          border-color: var(--g-cipher-color-dark) !important;
          box-shadow: none !important;
        }

        &:is(:active, :focus, :hover) {
          border-color: var(--g-cipher-color-dark);
          box-shadow: none;
        }
      }

      .rc-slider-step {
        .rc-slider-dot {
          background-color: var(--g-cipher-color-white);
          border: 1px solid var(--g-cipher-color-dark);

          &-active {
            background-color: var(--g-cipher-color-dark);
          }
        }
      }

      .rc-slider-mark {
        display: none;
      }
    }
  }

  &-notification {
    animation: notification 0.1s ease-in-out forwards;
    background-color: #a0a0a0;
    border-radius: 0.5rem;
    color: var(--g-cipher-color-white);
    font-size: 0.8rem;
    margin: 0;
    max-height: 100%;
    opacity: 0;
    overflow: hidden;
    padding: 0px;
    position: relative;
    width: min(600px, 100vw - 2rem);
    transition: all .3s ease-in-out;
    margin-bottom: 0;

    p {
      margin: 0;
      margin-left: 30px;
      font-size: .85rem;
      transition: all .3s ease-in-out;

      &:before {
        border: 2px solid var(--g-cipher-color-white);
        border-radius: 100%;
        color: var(--g-cipher-color-white);
        content: "i";
        font-weight: 800;
        height: 15px;
        left: 1rem;
        line-height: 15px;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
      }
    }

    max-height: 100%;
    opacity: 1;
    padding: 1rem;
    width: calc(100% - 2rem);

    h3 {
      margin: 0 0 0.5rem 30px;
      font-size: 1.1rem;
    }

    &--hidden {
      animation-name: notification-hidden;

      p {
        max-height: 0;
      }
    }

    &--warning {
      background-color: var(--g-cipher-color-primary);
      color: var(--g-cipher-color-black);

      p:before {
        border-color: var(--g-cipher-color-dark);
        color: var(--g-cipher-color-black);
        content: "!";
      }
    }

    &--positive {
      background-color: #5edbc3;
      color: var(--g-cipher-color-black);
    }

    @at-root .g-cipher-main  & {
      border-radius: .25rem;
    }
  }

  &-modal-overlay {
    background-color: var(--g-cipher-color-white);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;

    @media screen and (min-width: 500px) {
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &-modal {
    background: var(--g-cipher-color-white);
    border-radius: .5rem;
    box-shadow: none;
    max-height: 100vh;
    max-width: calc(500px - 2rem);
    overflow-y: auto;
    padding: 1rem;
    padding-bottom: 10vh;
    position: relative;
    width: 100%;
    z-index: 101;

    &-content {
      > *:first-child {
        margin-top: 0;
      }
    }

    @media screen and (min-width: 500px) {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      padding-bottom: 0;
      max-height: 90vh;
      max-width: clamp(calc(500px - 2rem), calc(100vw - 4rem), 800px);
    }

    &-close {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 1.5rem;
      position: absolute;
      right: 10px;
      top: 10px;
      color: var(--g-cipher-color-black);
    }
  }

  &-footer {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    row-gap: 1rem;
    width: min(600px, calc(100vw - 2rem));

    > * {
      margin: 0;
    }

    &__credits {
      opacity: 0.5;
    }
  }

  &-button {
    appearance: none;
    border: 1px solid;
    border-color: transparent;
    border-radius: .5rem;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &--small {
      font-size: 0.75rem;
      padding: 0.25rem .5rem;
    }

    &--primary {
      background-color: var(--g-cipher-color-dark);
      color: var(--g-cipher-color-white);

      .g-cipher-main & {
        border-color: var(--g-cipher-color-white);
      }
    }

    &--secondary {
      background-color: var(--g-cipher-color-white);
      color: var(--g-cipher-color-black);

      .g-cipher-main & {
        border-color: var(--g-cipher-color-dark);
      }
    }

    &--blank {
      background-color: transparent;
      border-color: transparent;
      color: var(--g-cipher-color-black);
    }

    &--blank-inverted {
      color: var(--g-cipher-color-white);
    }

    @at-root .g-cipher-main  & {
      border-radius: .25rem;
    }

    &--error {
      animation: button_error .5s ease forwards;
    }
  }
}

@keyframes notification {
  from {
    max-height: 0%;
    opacity: 0;
    padding: 0px;
    width: 100%;
  }
  to {
    max-height: 100%;
    opacity: 1;
    padding: 1rem;
    width: calc(100% - 2rem);
  }
}

@keyframes notification-hidden {
  from {
    max-height: 100%;
    opacity: 1;
    padding: 1rem;
    width: calc(100% - 2rem);
  }
  to {
    max-height: 0;
    opacity: 0;
    padding: 0px;
    width: 100%;
    margin-bottom: -1rem;
  }
}

@keyframes button_error {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
}